<template>
  <CCol v-if="!show" class="col-12 text-end">
    <CButton variant="ghost" style="padding: 0 !important; color: #0068AD; text-decoration: underline" @click="handleShowForm">
      <svg width="17" height="17" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2570_35000)">
          <path d="M20.625 11.75H12.75V3.875H11.25V11.75H3.375V13.25H11.25V21.125H12.75V13.25H20.625V11.75Z" fill="#677A89"/>
        </g>
        <defs>
          <clipPath id="clip0_2570_35000">
            <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
        </defs>
      </svg>
      <span class="additionBtn"> Add new field </span>
    </CButton>
  </CCol>

  <CCol v-else class="col-12">
    <CustomFieldForm :section-id="sectionId" @update="handleUpdate" @cancel="show = false" />
  </CCol>
</template>

<script>
import CustomFieldForm from './CustomFieldForm'
import { mapGetters } from 'vuex'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'AddCustomField',
  components: { CustomFieldForm },
  props: {
    sectionId: { type: Number, required: true },
  },
  emits: ['update'],
  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'projectPermissions',
    }),
  },
  methods: {
    handleUpdate() {
      this.show = false
      this.$emit('update')
    },
    handleShowForm() {
      !this.permissions.editOrCreate
        ? this.$notify(permissionError)
        : this.show = true
    }
  },
}
</script>

<style scoped>

</style>
